import { BrowserRouter, Routes, Route } from "react-router-dom";
import HomePage from "./pages/HomePage";
import BlogsPage from "./pages/BlogsPage";
import Layout from "./pages/Layout";
import OtpEntry from "./pages/OtpEntry";
import NewPassword from "./pages/NewPassword";
import ForgotPassword from "./pages/ForgotPassword";
import SignIn from "./pages/SignIn";
import Publishers from "./pages/PublisherPage";
import PlacementPage from "./pages/PlacementPage";
import Users from "./pages/Userpage"
import AddUserPage from "./pages/AddUser";
import EditUserPage from "./components/Users/EditUser";
import UnverifiedPage from "./pages/ToBeVerified"
import UpdateKMD from "./pages/UpdateKMD";
import { useEffect, useState } from "react";
console.log("test")
// import ResetPassword from "./components/SignIn/ResetPassword";
function App() {
  const [name, setname] = useState("")
  useEffect(()=>{
    return () => { localStorage.removeItem("cachedUrl") };
  },[])
  return (
    <BrowserRouter>
      <Routes>
        <Route path="forgot-password" element={<ForgotPassword />} />
        <Route path="new-password" element={<NewPassword />} />
        <Route path="otp-entry" element={<OtpEntry />} />
        <Route path="sign-in" element={<SignIn setname={setname} />} />
        <Route path="update-kmd" element={<UpdateKMD  />} />
        <Route path="unverified" element={<UnverifiedPage/>}></Route>
        <Route path="set-password/:token" element={<NewPassword />} />

        <Route path="/" element={<Layout name={name}/>}>
          
          <Route index element={<HomePage />} />
          <Route path="users" element={<Users />} />
          <Route path="add-user" element={<AddUserPage />} />
          <Route path="edit-user/:userId" element={<EditUserPage />} />
          <Route
            path="placements/:website/:id/:publisher/:publisherid/:placementid?"
            element={<PlacementPage />}
          />
          <Route path="publisher/:name/:id" element={<Publishers />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
